@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

html {
  width: 100%;
  height: 100%;
  font-size: 10px;
  font-family: "Lato", sans-serif;
}

body {
  width: 100%;
  position: relative;
  overflow-x: hidden;
  height: 100%;
  margin: 0;
  background-color: #141414;
}

/* Range Slider as a Single Thumb Slider */
.range-slider__thumb[data-lower] {
  width: 0px !important;
}
.range-slider__range {
  border-radius: 6px;
}

.range-slider__range {
  background: #e24856 !important;
}

.range-slider__thumb[data-upper] {
  background: #e24856 !important;
}

#single_slider {
  background-color: #2e2e2e;
}
