.container {
  padding: 0rem 4rem 5rem 4rem;
  color: white;
}

.container .heading {
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
}

.container .cardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 4rem;
  gap: 2rem;
}

.container .cardContainer .card {
  display: flex;
  gap: 3rem;
  padding: 2rem 3rem;
  background-color: #2e2e2e;
  border-radius: 10px;
  align-items: center;
}

.container .cardContainer .card .iconContainer {
  display: flex;
  background-color: #0277b5;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
}

.container .cardContainer .card .cardText {
  font-size: 1.5rem;
  text-align: center;
  width: 75%;
  font-weight: 500;
}

.cardLg {
  display: none;
}

.container .demoContainer {
  display: none;
}

@media screen and (min-width: 960px) {
  .container {
    padding: 0rem 12rem;
  }

  .container .heading {
    font-size: 4rem;
    margin-bottom: 5rem;
    font-weight: 500;
  }

  .container .cardContainer {
    display: none;
  }

  .cardLg {
    display: flex;
    justify-content: space-between;
  }

  .container .cardLg .card .iconContainer {
    display: flex;
    background-color: #2e2e2e;
    justify-content: center;
    align-items: center;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    margin: 0 auto;
  }

  .container .cardLg .card {
    padding: 2rem 3rem;
    border-radius: 10px;
    align-items: center;
    width: 30%;
    position: relative;
  }

  .container .cardLg .card .cardText {
    font-size: 1.5rem;
    text-align: center;
    width: 70%;
    margin: 0.8rem auto;
    font-weight: 400;
  }

  .container .cardLg .card .cardText.middle {
    width: 80%;
  }

  .container .cardLg .card .cardTitle {
    font-size: 2rem;
    text-align: center;
    margin-top: 3rem;
    width: 100%;
  }

  .container .demoContainer {
    display: flex;
    margin-top: 5rem;
    justify-content: center;
  }

  .container .demoBtn {
    display: flex;
    border: none;
    border-radius: 30px;
    padding: 1.5rem 3.5rem;
    align-items: center;
    background-color: #e24856;
    color: white;
    gap: 1rem;
    font-size: 500;
    font-size: 1.5rem;
  }

  .container .cardLg .card.line::after {
    position: absolute;
    width: 300px;
    height: 1px;
    left: 65%;
    top: 25%;

    content: "";
    background-color: #c5bebe;
  }
}
