.container {
  padding-top: 3rem;
}

.container .heading {
  font-size: 2rem;
  color: white;
  text-align: center;
  line-height: 3rem;
  font-weight: 600;
}

.container .text {
  color: white;
  display: none;
}

.container .imgContainer {
  display: none;
}

@media screen and (min-width: 960px) {
  .container {
    width: 50%;
  }
  .container .imgContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 2rem;
  }

  .container .imgContainer img {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
  }

  .container .heading {
    font-size: 3rem;
    color: white;
    text-align: center;
    line-height: 5rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 4rem;
  }

  .container .text {
    color: white;
    display: block;
    text-align: center;
    font-size: 2rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    margin-bottom: 6rem;
  }
}
