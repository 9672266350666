.navbar {
  width: 100%;
  display: flex;
  background: transparent;
  justify-content: space-between;
  padding: 2rem 3rem;
  align-items: center;
}

.navbar .logo {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar .logo .logoContainer {
  width: 90%;
  display: flex;
  align-items: center;
}

.navbar .navLink {
  display: none;
}

.navbar .toggleIcon {
  width: 50%;
}

@media screen and (min-width: 960px) {
  .navbar {
    /* padding: 2rem 2rem; */
    align-items: flex-start;
    justify-content: space-between;
    padding: 2rem 10rem 2rem 10rem;
  }
  .navbar .logo {
    width: 20%;
    align-items: flex-center;
  }
  .navbar .navLink {
    display: flex;
    /* width: 60%; */
    justify-content: space-between;
    align-items: center;
  }

  .navbar .navLink .linkItem {
    color: black;
    font-size: 1.5rem;
    text-decoration: none;
    font-weight: 500;
  }

  .navbar .navLink .linkItem.active {
    color: #0077b5 !important;
  }

  .navbar .navLink .middleMenuLinks {
    display: flex;
    gap: 4rem;
    align-items: center;
  }
  .navbar .toggleIcon {
    display: none;
  }
  .navbar .toggleContainer {
    display: none;
  }

  .navbar .navLink .actionMenu {
    display: flex;
    gap: 3rem;
    align-items: center;
  }
  .navbar .navLink .actionMenu .clientLogin {
    color: #0077b5;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 500;
  }

  .navbar .navLink .actionMenu .demoBtn {
    padding: 1.3rem 2rem;
    background-color: #e24856;
    color: white;
    border-radius: 30px;
    border: none;
    font-weight: 500;
    font-size: 1.5rem;
  }
}
