.container {
  padding: 4rem 3rem;
  color: white;
}

.container .heading {
  font-size: 2.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: 4rem;
}

.slider {
  height: 25px; /* Specified height */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  background: #d3d3d3;
}

.slider::-webkit-slider-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #04aa6d; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #04aa6d; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.container .sliderInputContainer {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
}

.container .importanceList {
  display: none;
}

.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 30%; /* Full-width */
  height: 5px; /* Specified height */
  background: #cce4f0; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  border-radius: 32px;
  accent-color: #f55050;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 12px; /* Set a specific slider handle width */
  height: 12px; /* Slider handle height */
  background: #0077b5; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 12px; /* Set a specific slider handle width */
  height: 12px; /* Slider handle height */
  background: #0077b5; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-progress {
  background-color: #0077b5;
}
.slider::-moz-range-track {
  background-color: #cce4f0;
}
.slider::-ms-fill-lower {
  background-color: #0077b5;
}
.slider::-ms-fill-upper {
  background-color: #cce4f0;
}

@media screen and (min-width: 960px) {
  .container {
    padding: 21rem 12rem 6rem 12rem;
  }

  .container .heading {
    font-size: 3.8rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  .container .sliderContainer {
    display: none;
  }

  .container .importanceList {
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    gap: 4rem;
    flex-wrap: wrap;
  }

  /* .container .importanceList:nth-child(3) {
    width: 100% !important;
  } */
}
