.container .heading {
  color: white;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  display: block;
  position: relative;
  text-align: center;
}

.container .message {
  font-size: 1.5rem;
  line-height: 2.8rem;
  text-align: center;
}

.container .icon img {
  width: 70%;
  height: 100%;
}

.container .icon {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  height: 185px;
}

@media screen and (min-width: 960px) {
  .container {
    background-color: #2e2e2e;
    padding: 2rem 20rem;
    border-radius: 10px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .container.half {
    width: calc(50% - 2rem);
    padding: 2rem 1rem;
  }

  .container .icon img {
    width: 70%;
    height: 100%;
  }

  .container .icon {
    width: 40%;
  }

  .container .info {
    width: 60%;
  }

  .container .icon {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    height: 200px;
  }

  .container .heading {
    text-align: left;
  }

  .container.half .message {
    width: 100%;
  }

  .container .message {
    text-align: left;
    width: 50%;
  }
}
