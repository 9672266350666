.container {
  padding: 0rem 2rem;
}

.container .seperator {
  background: #484848;
  color: #484848;
  /* border-color: #484848; */

  border: 0;
  height: 2px;
  margin-top: 4rem;
  margin-bottom: 0rem;
  display: block;
}

@media screen and (min-width: 960px) {
  .container {
    padding: 0rem 7rem 10rem 7rem;
    display: flex;
    gap: 4rem;
  }

  .container .seperator {
    display: none;
  }
}
