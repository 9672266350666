.container {
  color: white;
}

.container .headingContainer {
  color: #e24856;
  padding: 3.5rem;
  text-align: center;
}

.container .headingContainer .heading {
  font-size: 2.5rem;
  font-weight: 500;
}

.container .contentContainer {
  padding: 3rem 3rem;
}

.container .contentContainer .text {
  font-size: 1.5rem;
}

.container .contentContainer .card {
  margin-top: 3rem;
}

.container .contentContainer .card .cardHeading {
  font-weight: 500;
  font-size: 1.8rem;
}

.container .contentContainer .card .smallText {
  font-weight: 400;
  font-size: 1.7rem;
}

.container .contentContainer .text.last {
  margin-top: 4rem;
}

@media screen and (min-width: 960px) {
  .container .headingContainer {
    padding: 6rem 12rem;
  }

  .container .headingContainer .heading {
    font-size: 4rem;
  }

  .container .contentContainer {
    padding: 6rem 12rem;
    width: 90%;
  }

  .container .contentContainer .text {
    font-size: 2rem;
  }

  .container .contentContainer .card {
    margin-top: 3rem;
  }

  .container .contentContainer .card .cardHeading {
    font-weight: 600;
    font-size: 2.2rem;
  }
}
