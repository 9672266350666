.footer {
  width: 100%;
  background: transparent;
  color: white;
  padding: 4rem 3rem 2rem 3rem;
}

.footer .linkContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer .linkContainer .linkSubContainer {
  width: 60%;
}

.footer .textLogo {
  display: none;
}

.footer .footerLogoContainer {
  width: 80%;
  margin-bottom: 2rem;
}

.footer .footerLogoContainer .footerLogo {
  width: 70%;
}

.footer .linkItem {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 500;
  cursor: pointer;
  color: #ffffff;
}

.footer .demoBtn {
  padding: 1.3rem 2rem;
  background-color: #e24856;
  color: white;
  border-radius: 30px;
  border: none;
  font-weight: 500;
  font-size: 1.5rem;
}

.footer .copyRightContainer {
  margin-top: 4rem;
  border-top: solid;
  border-top-width: 0.5px;
  border-top-color: #dbdbdb;
  padding-top: 2rem;
}

.footer .copyRight {
  text-align: center;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}

@media screen and (min-width: 960px) {
  .footer {
    padding: 4rem 14rem 2rem 14rem;
  }

  .footer .container {
    display: flex;
    justify-content: space-between;
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .footer .subContainer {
    width: 100%;
    justify-content: space-between;
    display: flex;
  }
  .footer .linkContainer {
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
  }

  .footer .linkContainer .linkSubContainer {
    width: auto;
  }

  /* .footer .linkContainer .linkSubContainer.middle {
    width: 60%;
  } */

  .footer .textLogo {
    display: block;
    font-size: 1.8rem;
    margin-bottom: 2.5rem;
    color: white !important;
  }

  .footer .linkItem {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: 400;
  }

  .footer .footerLogo,
  .footer .footerLogoContainer {
    display: none;
  }

  .footer .copyRight {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .mobile_only {
    display: none;
  }
}
