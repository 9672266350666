.hero {
  padding: 4rem 3rem;
  min-height: 50vh;

  justify-items: center;
  background-size: cover;
  position: relative;
}

.hero .title {
  font-size: 4rem;
  color: white;
  width: 95%;
  font-weight: 500;
}

.hero .textsm {
  color: white;
  font-size: 2rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
  width: 80%;
  margin-left: 2.5rem;
  font-weight: 300;
}

.hero .textlg {
  display: none;
}

.hero .textContainer {
  position: relative;
}

.hero .textBar {
  position: absolute;
  height: 100%;
  width: 5px;
  background: #e24856;
  border-radius: 50px;
}

.hero .salesBtn {
  padding: 1.3rem 2rem;
  background-color: #e24856;
  color: white;
  border-radius: 30px;
  border: none;
  font-weight: 500;
  font-size: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

@media screen and (min-width: 960px) {
  .hero {
    padding: 10rem 12rem;
    min-height: 70vh;

    background-size: cover;
    align-items: center;
  }

  .hero .title {
    font-size: 5rem;
    font-weight: 600;
    width: 100%;
  }

  .hero .textlg {
    font-size: 1.7rem;
    width: 45%;
    display: block;
    color: white;

    margin-top: 3rem;
    margin-bottom: 0rem;
    margin-left: 2rem;
    font-weight: 300;
  }
  .hero .textsm {
    display: none;
  }

  .hero .salesBtn {
    display: none;
  }
}
