.container {
  padding-top: 6rem;
  color: white;
}

.container .title {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  color: #e24856;
}

.container .text {
  text-align: center;
  font-size: 1.6rem;
  margin-top: 1rem;
}

.container .form {
  margin-top: 5rem;
}

.container .form .form_field {
  display: block;
  width: 100%;
  padding: 1.5rem 2rem;
  background-color: transparent;
  border: solid 1.5px #959595;
  margin-bottom: 1.8rem;
  color: white;
  font-size: 1.8rem;
  border-radius: 50px;
  outline: none;
}

.container .form .form_field::placeholder {
  color: #959595;
}

.container .form .phone_input {
  display: flex !important;
  align-items: center !important;
}

.container .form .form_select {
  display: block;
  width: 100%;
  padding: 1.5rem 2rem;
  background-color: transparent;
  border: solid 1.5px #959595;
  margin-bottom: 1.5rem;
  color: white;
  font-size: 1.8rem;
  border-radius: 50px;
  outline: none;
  appearance: none;
}

.container .form .select_container {
  position: relative;
}

.container .form .select_btn {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.container .form .form_textarea {
  width: 100%;
  background-color: transparent;
  height: 200px;
  border: solid 1.5px #959595;
  border-radius: 20px;
  resize: none;
  padding: 2rem;
  font-size: 1.8rem;
  color: white;
}

.container .form .form_textarea::placeholder {
  color: #959595;
}

.container .form .policyContainer {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  margin: 0 auto;
  margin-top: 2rem;
  width: 85%;
}

.container .form .policyContainer .checkInput {
  display: inline-block;
}
.container .form .policy_text {
  font-size: 1.2rem;
  margin-top: 0;
}
.container .form .policy {
  text-decoration: underline !important;
  color: white !important;
}

.container .form .btnContainer {
  width: 100%;
  margin-top: 4rem;
}

.container .form .btnContainer .btn {
  width: 100%;
  padding: 1.5rem 0;
  color: white;
  font-size: 1.8rem;
  font-weight: 600;
  border: none;
  border-radius: 30px;
  margin-bottom: 10rem;
  background-color: #e24856;
}

.menu_flags_button {
  color: white;
  width: 100%;
}

.menu_flags {
  color: #0077b5;
}

.menu_flags ul {
  width: 100%;
}

.container .menu_flags_button {
  border: none;
}
.container .form .form_select:invalid {
  color: #959595;
}
@media screen and (min-width: 960px) {
  .container {
    padding-top: 4rem;
    padding-bottom: 4rem;

    width: 50%;
    padding-left: 7rem;
    padding-right: 7rem;
    border-radius: 3rem;
  }

  .container .form {
    margin-top: 1.3rem;
    margin-bottom: 3rem;
    color: #757272ee;
  }

  .container .title {
    font-size: 2.8rem;
    color: #e24856;
    text-align: left;
  }

  .container .text {
    text-align: left;
    font-size: 1.8rem;
    margin-top: 1.2rem;
    color: white;
  }

  .container .form .form_textarea {
    width: 100%;

    height: 100px;
    border: solid 1.5px #959595;
    border-radius: 10px;
    resize: none;
    padding: 2rem;
    font-size: 1.8rem;
    color: white;
  }

  .container .form .form_select {
    display: block;
    width: 100%;
    padding: 1.2rem 2rem;

    border: solid 1.5px #959595;
    margin-bottom: 1.5rem;
    color: white;
    font-size: 1.8rem;
    border-radius: 50px;
    outline: none;
    appearance: none;
  }

  .container .form .form_select:invalid {
    color: #959595;
  }

  .container .form .form_select .placeholder {
    font-size: 1rem;
  }

  .container .form .form_field {
    display: block;
    width: 100%;
    padding: 1.2rem 2rem;

    border: solid 1.5px #959595;
    margin-bottom: 1.5rem;

    font-size: 1.8rem;
    border-radius: 50px;
    outline: none;
    color: white;
  }

  .container .form .form_textarea::placeholder {
    color: #959595;
    font-size: 1.8rem;
  }

  .container .form .form_field::placeholder {
    color: #959595;
    font-size: 1.8rem;
  }

  .container .company_group {
    display: flex;
    gap: 2rem;
    width: 100%;
  }

  .container .company_group .company_input {
    width: 50%;
  }

  .container .form .select_btn {
    position: absolute;
    right: 20px;
    top: 40%;
    transform: translateY(-50%);
  }

  .container .form .btnContainer {
    margin-top: 2rem;
  }

  .container .form .btnContainer .btn {
    margin-bottom: 0;
    background-color: #e24856;
    color: white;
    font-weight: 400;
  }

  .container .form .policy_text {
    color: white;
    font-size: 1.2rem;
  }

  .container .form .form_error {
    font-size: 1rem;
    color: red;
  }
}
