.container .heading {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  display: inline-block;
  position: relative;
}

.container .message {
  font-size: 1.5rem;
  line-height: 2.8rem;
}

.container .icon {
  display: none;
}

.container {
  border-left: solid 1.3px #c5bebe;
  padding-left: 2rem;
}

@media screen and (min-width: 960px) {
  .container {
    padding: 0.5rem 1rem 0.5rem 3rem;
    border-left: none;
    width: 33.3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.8rem;
  }

  .container .message {
    font-size: 1.5rem;
    line-height: 2.3rem;
    width: 80%;
  }

  .container .icon {
    display: flex;
    width: 50px;
    height: 50px;
    background-color: white;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-bottom: 2rem;
  }
}
