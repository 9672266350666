.page {
  background-color: #2e2e2e;
  min-height: 100vh;
}

@media screen and (min-width: 960px) {
  ._footer {
    display: none;
  }
}
