.container {
  color: white;
  padding: 3rem 4rem;
}

.container .heading {
  font-size: 2.5rem;
  text-align: center;
}

.container .platforms {
  margin-top: 4rem;
}

.container .platforms .platformCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container .platforms .platformCard .platform {
  font-size: 1.5rem;
  margin-top: 1rem;
  text-align: center;
}

.container .platforms .platformCard .subText {
  color: #e24856;
  text-align: center;
  font-size: 1.3rem;
}

.container .platforms .platformCard .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.5rem;
}

@media screen and (min-width: 960px) {
  .container {
    color: white;
    padding: 6rem 4rem;
  }

  .container .heading {
    font-size: 3.5rem;
  }
  .container .platforms {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6rem;
  }

  .container .platforms .platformCard .subText {
    margin-top: 1rem;
  }
}
